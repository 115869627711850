import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import {
    of as observableOf,
    throwError as observableThrowError,
    Observable,
} from "rxjs";
import { AppConfigService } from "../app-config.service";

@Injectable({
    providedIn: "root",
})
export class SportService {
    public api: any;
    public httpAuthHeaders: any;

    constructor(
        private config: AppConfigService,
        private http: HttpClient,
        private localStorage: LocalStorage,
        private router: Router,
        private toastr: ToastrService
    ) {
        this.api = config.load();
        this.setAuthHeader();
    }

    setAuthHeader() {
        this.localStorage.getItem("user").subscribe((data) => {
            this.httpAuthHeaders = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + data,
                }),
            };
        });
    }

    getPublicCategory(id: number): Observable<any> {
        const url = this.api.base + `v1/allsports/{sportId}/categories/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => this.handleError)
        );
    }

    getPublicSportCategory(id: number): Observable<any> {
        const url = this.api.base + `v1/allsports/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => this.handleError)
        );
    }

    getPublicSports(): Observable<any> {
        const url = this.api.base + "v1/allsports";
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => this.handleError)
        );
    }

    getPublicFixture(): Observable<any> {
        const url = `${this.api.base}v1/allfixtures`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => this.handleError)
        );
    }

    getPublicHighlight(): Observable<any> {
        const url = `${this.api.base}v1/allfixtures?highlight=1`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => this.handleError)
        );
    }

    getPublicCateporySports(id: number): Observable<any> {
        const url = `${this.api.base}v1/allfixtures?category_id=${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => this.handleError)
        );
    }

    getSports(token): Observable<any> {
        const url = this.api.base + "v1/sports";
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => this.handleError)
        );
    }

    getSportById(id): Observable<any> {
        const url = this.api.base + "v1/sports/" + id;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: this.api.public_token,
                // Authorization: "Bearer " + localStorage.getItem("user"),
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError((err) => this.handleError)
        );
    }

    getAdminSportById(id): Observable<any> {
        const url = this.api.base + "v1/sports/" + id;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user"),
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError((err) => this.handleError)
        );
    }

    getSportCategoryDetail(id, categoryId): Observable<any> {
        const url =
            this.api.base + "v1/sports/" + id + "/categories/" + categoryId;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user"),
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError((err) => this.handleError)
        );
    }

    register(data, sportId, categoryId, token): Observable<any> {
        const url = `${this.api.base}v1/user/sports/${sportId}/categories/${categoryId}`;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.put(url, data, httpOptions).pipe(
            map((res) => res),
            catchError((err) => this.handleError)
        );
    }

    exportSportAthletes(sportId): Observable<any> {
        const url = `${this.api.base}v1/sports/${sportId}/export_athletes`;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
            responseType: "blob" as "json",
        };

        return this.http.post(url, null, httpOptions).pipe(
            map((res) => res),
            catchError(async (err) => console.log(err))
        );
    }

    exportSportCategoryAthletes(sportId, category_id): Observable<any> {
        const url = `${this.api.base}/v1/sports/${sportId}/categories/${category_id}/export_athletes`;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
            responseType: "blob" as "json",
        };

        return this.http.post(url, null, httpOptions).pipe(
            map((res) => res),
            catchError(async (err) => console.log(err))
        );
    }

    /**
     * Handle error from the request.
     * @param error error handle
     */
    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;

        if (error instanceof Response) {
            const body = error.json() || "";
            const err = JSON.stringify(body);
            console.log(err);
            errMsg = `${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return observableThrowError(errMsg);
    }
}
